import React  from 'react'
//width
//0 = hidden (0px)
//1 = mini view (50px)
//2 = full width (350px)

// boxShadow: 'inset -2rem 0 2rem -2rem rgba(0,0,0,.26)'
export default function Menu({ children, width }) {
    const leftPane = { width: width ? width+'px' : '350px', height: '100%',transition: '0.2s'}

    return (
        <div style={leftPane} >
            {children}
        </div>
    )
}
