import React, { useEffect, useState } from 'react'

import { TextField, TextArea, CheckBox, Paper } from '../ui/core';
export default function Item({ item, onSave, readOnly, hideArchive, hideNote }) {
    const [editItem, setEditItem] = useState();


    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    const handleChangeAndSave = (attribute, value) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }

    const handleFileUploadToBase64 = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        let file = ev.target.files[0];
        console.log('file', file);
        if (!file) return;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let tmpItem = {};
            tmpItem.image = reader.result;
            tmpItem._id = item._id;
            onSave(tmpItem);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    useEffect(() => {
        setEditItem(item);
    }, [item])

    if (!item) return null;

    return (
        <div className="padding">
            <Paper className="padding">
                {editItem && <>
                    <TextField id="name" label="Naam" value={editItem.name} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextField>
                    <TextField id="address" label="Adres" value={editItem.address} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextField>
                    <TextField id="pobox" label="Postcode" value={editItem.pobox} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextField>
                    <TextField id="place" label="Plaats" value={editItem.place} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextField>
                    {!hideNote && <TextArea id="description" label="Notitie" value={editItem.description} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextArea>}
                    <br /><br />

                    <TextField id="color" label="Kleurcode huisstijl" value={editItem.color} onChange={handleChange} onBlur={handleSave} readOnly={readOnly}></TextField>
                    <br /><br />
                    <div className='label'>Logo (hoogte 100px)</div>
                  
                   {item.image &&  <img src={item.image } alt="Scherp" style={{ height: '60px', width: 'auto', padding: '23px' }} />}<br/>
                    <input type='file' id='logo' onChange={handleFileUploadToBase64} accept="image/gif, image/jpeg, image/png" />
                    <br /><br />
                    {!hideArchive && <><div className="label">Archiveren</div>
                        <CheckBox id="archived" label="In archief plaatsen" checked={editItem.archived || false} onChange={(ev) => { handleChangeAndSave('archived', ev.target.checked) }} readOnly={readOnly} />
                    </>}
                </>}
            </Paper>
        </div>
    )
}
