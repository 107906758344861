import React, { useRef, useEffect } from 'react'

export default function Content({children,scrollTop}) {
    const contentRef = useRef();
    const contentPane = { width: '100%',minHeight:'800px' ,height: '100%', backgroundColor: 'white',paddingBottom:'100px' }
 
    useEffect(() => { 
       if(contentRef.current){ 
           contentRef.current.scrollTop = 0;
       }
    }, [scrollTop])
  
    return (
        <div style={contentPane} ref={contentRef} >
            {children}
        </div>
    )
}
