import React, { useContext } from 'react'
import { ContextUI } from '../../uiContext';
import './tabs.css';
const Tabs = function(props) {
    const contextUI = useContext(ContextUI);
    const primaryColor = contextUI.primary.color;
    let tabStyle = { display: 'table-cell', padding: '8px', cursor: 'pointer', color: '#656565' };
    let tabStyleSelected = Object.assign({}, tabStyle);
    tabStyleSelected.borderBottom = '3px solid ' + primaryColor;
    tabStyleSelected.color = primaryColor

    const onChangeTab = (i,child)=>{
        if(child.props.id){
            props.onChange(child.props.id);
        }else{
            props.onChange(i);
        }
       
    }

    const tabSelected = (i,child) =>{
        if(child.props.id){
            return  props.value === child.props.id;
        }else{
            return  props.value === i;
        }
    }

    let items = React.Children.map(props.children, (child, i) => {
        if(child){
            return <div onClick={() => onChangeTab(i,child)} style={tabSelected(i,child) ? tabStyleSelected : tabStyle} className='tab'>{child}</div>
        }else{return null}
        
    })

    return (
        <React.Fragment>
            <div style={{ borderBottom: '1px solid silver', overflowX: 'auto', width: '100%'}}>
                <div style={{ paddingLeft: '10px' ,fontSize:'13px' }}>
                    {items}
                </div>
            </div>
        </React.Fragment>

    )
}
export {Tabs}