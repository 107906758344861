import React, { useEffect, useState } from 'react'
import Service from '../../../services/question';
import ServiceSurvey from '../../../services/survey';
import { Button } from '../../ui/button'
import { Dialog } from '../../ui/dialog'
import { DialogActions } from '../../ui/dialogActions'
import { DialogContent } from '../../ui/dialogContent'
import { DialogTitle } from '../../ui/dialogTitle'
import { Typography } from '../../ui/typography'
import { v4 as uuidv4 } from 'uuid';
import readXlsxFile from 'read-excel-file'
export default function Import({ survey,onSaveSurvey, onClose }) {
    const [file, setFile] = useState();
    const [importRows, setImportRows] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const input = document.getElementById('inputexcelfile');
        input.addEventListener('change', importExcel)
        return () => {
            input.removeEventListener('change', importExcel);
        }
    }, [])

    const importExcel = (ev) => {
        if (ev.target && ev.target.files && ev.target.files.length > 0 && ev.target.files[0].name.toLowerCase().indexOf('.xlsx') > 0) {
            setFile(ev.target.files[0])
            ev.target.value = '';
        }
    }

    const doImport = async () => {
        if (file) {
            let errors = [];
            let tmpQuestions = [];
            let mainProcesses = Object.assign([], survey.mainProcesses);

            const rows = Object.assign([], importRows)
            if (rows.length > 1) {
                for (let r = 1; r < rows.length; r++) {
                    let question = {
                        active: true,
                        survey: survey._id,
                        created: new Date()
                    };

                    try {
                        question.sort = parseInt(rows[r][0]);
                        if (isNaN(question.sort)) { throw "Geen nummer" }
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', volgorde'); }

                    try {
                        question.type = parseInt(rows[r][1].split(':')[0]);
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', type vraag'); }

                    try {
                        if (question.type === 0) {
                            question.text = rows[r][2];
                        } else {
                            question.question = rows[r][2];
                        }

                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', vraag'); }

                    try {
                        let options = [];
                        if (rows[r][3]) {
                            let tmpOptions = rows[r][3].split(';');
                            for (let o = 0; o < tmpOptions.length; o++) {
                                if (tmpOptions[o].split(':')[1] && tmpOptions[o].split(':')[1].trim().length > 0) {
                                    let score = 0;
                                    try{
                                        score= tmpOptions[o].split(':')[0];
                                        score = parseInt(score);
                                    }catch(ex){
                                        errors.push('Regel ' + (r + 1) + ', score bij optie niet geldig');
                                        console.log(ex);
                                    }
                                    options.push({
                                        _id: uuidv4(),
                                        score: score,
                                        text: tmpOptions[o].split(':')[1],
                                        active: true
                                    })
                                }
                            }
                        }
                        question.options = options;
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', opties'); }

                    try {
                        if (!rows[r][4] || !rows[r][5]) {
                            throw "Geen hoofd of subprocess";
                        }
                        //find mainprocess
                        let mainID;
                        let subID;
                        for (let p = 0; p < mainProcesses.length; p++) {
                            if (mainProcesses[p].title === rows[r][4] && mainProcesses[p].active===true) {
                                mainID = mainProcesses[p]._id;
                            }
                        }
                        if (!mainID) {
                            mainID = uuidv4();
                            let title = rows[r][4];
                            let code = title;
                            if( rows[r][4].indexOf(':')>0){
                                code = rows[r][4].split(':')[0]
                                title = rows[r][4].split(':')[1]
                            }
                            mainProcesses.push({ _id: mainID, title: title, subProcesses: [], code: code, active: true })
                        }
                        //find subprocess
                        for (let p = 0; p < mainProcesses.length; p++) {
                            if (mainProcesses[p]._id === mainID) {
                                for (let s = 0; s < mainProcesses[p].subProcesses.length; s++) {
                                    if (mainProcesses[p].subProcesses[s].title === rows[r][5] && mainProcesses[p].subProcesses[s].active===true) {
                                        subID = mainProcesses[p].subProcesses[s]._id;
                                    }
                                }
                                if (!subID) {
                                    subID = uuidv4();
                                    let title = rows[r][5];
                                    let code = title;
                                    if( rows[r][5].indexOf(':')>0){
                                        code = rows[r][5].split(':')[0]
                                        title = rows[r][5].split(':')[1]
                                    }
                                    mainProcesses[p].subProcesses.push({ _id: subID, title: title, code: code, active: true })
                                }
                            }
                        }

                        if (mainID && subID) {
                            question.subProcess = subID;
                        }

                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', hoofdprocess, subprocess'); }

                    try {
                        question.code = rows[r][6];
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', code'); }

                    try {
                        question.hasOpenText = rows[r][7].toLowerCase() === 'ja' ? true : false;
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', toelichting'); }

                    try {
                        question.hasImageUpload = rows[r][8].toLowerCase() === 'ja' ? true : false;
                    } catch (ex) { errors.push('Regel ' + (r + 1) + ', upload document'); }


                    tmpQuestions.push(question)
                }
            } 
            
            if (errors.length === 0) {
                //update processes
                await onSaveSurvey({_id:survey._id,mainProcesses:mainProcesses})

                

                //insert questions
                for (let i = 0; i < tmpQuestions.length; i++) {
                    await Service.insert(tmpQuestions[i]);
                }
                onClose();
            }
            setErrors(errors);
        }
    }

    useEffect(() => {
        if (file) {
            readXlsxFile(file, { sheet: 1 })
                .then((rows) => {
                    setImportRows(rows);
                })
        }


    }, [file])


    return (
        <Dialog onClose={onClose}>
            <DialogTitle><Typography component="h1">Importeren vragenlijst</Typography></DialogTitle>
            <DialogContent>
                <Typography component="h3">1. Selecteren Excel (xlsx) document</Typography><br />
                <Button variant="contained" color="primary" onClick={() => { document.getElementById('inputexcelfile').click() }}>Selecteer Excel document</Button>
                <input type="file" id="inputexcelfile" style={{ display: 'none' }} />
                <br /><br />
                {importRows.length > 0 && <>
                    Aantal gevonden vragen: {importRows.length - 1}
                </>}
                <br /><br />
                {
                    errors.length > 0 && <>
                        Aantal gevonden fouten: {errors.length}<br />
                        {errors.map((error, i) => {
                            return <div key={i}>- {error}<br /></div>
                        })}
                    </>
                }


            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Annuleren</Button>
                <Button onClick={doImport} disabled={importRows.length === 0}>Importeren</Button>
            </DialogActions>
        </Dialog>
    )
}
