import React, { useState, useEffect,useRef,useContext } from 'react';
import moment from 'moment';
import { Context } from '../../AppContext';
import ServiceSurveys from '../../services/survey';
import ServiceGeneral from '../../services/general';
export default function MonitorTimelinev2({ scores, monitor,selectedSurveys, onClick,onClickSurveys }) {
    const [years, setYears] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState();
    const ref = useRef()
    const context = useContext(Context);
    const [color,setColor ] = useState('#00698f')
   // const [selectedSurveys, setSelectedSurveys] = useState([]);

   useEffect(()=>{
       if(context.organisation && context.organisation.color){
        setColor(context.organisation.color)
       }
       
    }, [context.organisation])
    useEffect(() => {
        document.onselectstart = () => {
            return false;
        }
        return () => {
            document.onselectstart = () => {
                return true;
            }
        }
    }, [])
    useEffect(() => {
        if (scores) {
            let tmpYears = [];
            for (let i = 0; i < scores.surveys.length; i++) {
            
                if (scores.surveys[i].date) {
                    let year = moment(scores.surveys[i].date).year();
                  
                    if (tmpYears.indexOf(year) === -1) {
                        tmpYears.push(year);
                    }
                }
            }
            tmpYears = tmpYears.sort();
            setYears(tmpYears);
            scores.surveys.length > 0 && setSelectedSurvey(scores.surveys[scores.surveys.length - 1]._id)

        }
        setTimeout(()=>{ref.current &&   ref.current.scroll(0,ref.current.scrollHeight)},1000)
     
    }, [scores])

    const getColor = (survey) => {
        let score = 0;
        let amount = 0;
        for (let i = 0; i < scores.mainProcessScore.length; i++) {
          if (scores.mainProcessScore[i]._id.survey === survey._id) {
              //Overzicht toont alleen de scores die aan een process zijn gekoppeld
               if(scores.mainProcessScore[i]._id.mainProcessCode){
                score += scores.mainProcessScore[i].perc;
                amount++;
               }
       
           }
     }
     /*
        for (let i = 0; i < scores.subProcessScore.length; i++) {
            if (scores.subProcessScore[i]._id.survey === survey._id) {
                score += scores.subProcessScore[i].perc;
                amount++;
            }
        }
        */
        return ServiceSurveys.getColorFromRange(monitor.colorRange,ServiceGeneral.roundNumber(score / amount))
    }

    const styleDot = (survey) => {
        if (survey._id === selectedSurvey) {
            return { position: 'absolute', cursor: 'pointer', width: '20px', height: '20px', backgroundColor: getColor(survey), border: '2px solid black', borderRadius: '50% 50% 50% 50%', left: moment(survey.date).dayOfYear() - 11 + 'px', zIndex: '1' }

        } else {
            return { position: 'absolute', cursor: 'pointer', width: '20px', height: '20px', backgroundColor: getColor(survey), border: '2px solid white', borderRadius: '50% 50% 50% 50%', left: moment(survey.date).dayOfYear() - 11 + 'px', zIndex: '1' }
        }
    };

    const handleClick = (ev, survey) => {

     
        let tmpSurveys = Object.assign([], selectedSurveys);
        if (ev.shiftKey) { 
          
            if (tmpSurveys.find(s => { return s == survey })) { 
                tmpSurveys = tmpSurveys.filter(s=>{ return s !== survey})
            } else { 
                tmpSurveys.push(survey);
            }
         
        } else { 
            tmpSurveys = [survey]
         
        }
        onClick(survey);
        setSelectedSurvey(survey);
        onClickSurveys(tmpSurveys)
       // setSelectedSurveys(tmpSurveys)
     

    }


    return (
        <div style={{height:'400px',overflow:'auto'}} ref={ref}>
            {years.map(year => {
                return <div key={year} style={{ position: 'relative' }}>

                    <div style={{ color: 'white', backgroundColor: color, padding: '4px', textAlign: 'center', fontSize: 'large',minWidth:'200px' }}>{year}</div>
                    {scores.surveys.filter(survey => { return year === moment(survey.date).year()  }).map(survey => {
                        return <div key={survey._id} style={{ cursor: 'pointer', display: 'flex', backgroundColor: (selectedSurveys.indexOf(survey._id)>=0 ? '#e2dfdf' : 'white') }} onClick={(ev) => handleClick(ev, survey._id)}><div style={{ width: '40px', textAlign: 'right', padding: '4px', color: 'gray' }}>{moment(survey.date).format('D')}-{moment(survey.date).format('M')}</div><div style={{ borderLeft: `6px solid ${getColor(survey)}`, padding: '4px' }}> {survey.title}</div></div>
                    })}
                </div>
            })}
              <div className='label' style={{fontSize:'9px',padding:'4px'}}>Gebruik [SHIFT] om meerdere te selecteren</div>
        </div>

    )
}
