import React, { useEffect, useState } from 'react'

import { TextField, TextArea, CheckBox ,Paper} from '../ui/core';
export default function Item({ item, onSave }) {
    const [editItem, setEditItem] = useState();
    

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    const handleChangeAndSave= (attribute,value)=>{
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }

    useEffect(() => {
        setEditItem(item);
    }, [item])

    if(!item) return null;

    return (
        <div className="padding">
        <Paper  className="padding">
            {editItem && <>                
                <TextField id="name" label="Locatie" value={editItem.name} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextField id="address" label="Adres" value={editItem.address} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextField id="pobox" label="Postcode" value={editItem.pobox} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextField id="place" label="Plaats" value={editItem.place} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextArea id="description" label="Notitie" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea> 
               <br/><br/>
                <div className="label">Archiveren</div>
                    <CheckBox label="In archief plaatsen" checked={editItem.archived || false} onChange={(ev)=>{handleChangeAndSave('archived',ev.target.checked)}}/>
                    
              </>}
        </Paper>
        </div>
    )
}
