import React, { useContext, useEffect, useState } from 'react';
import Service from '../../services/evaluations';
import GeneralService from '../../services/general';
import ServiceUser from '../../services/user';
import ServiceDepartments from '../../services/department';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import { IconButton } from '../../components/ui/core';
import { Add, Help } from '../../components/ui/coreIcons';
import List from '../../components/module-evaluation/list';
import { SearchBar, Paper, Tabs, Menu, MenuItem } from '../../components/ui/core';
import Monitor from '../../components/module-evaluation/monitor';
import MainMenu from '../../components/mainmenu';
import Footer from '../../components/mainfooter';
export default function Index(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);

    const getSelectedTab = () =>{
        if(localStorage.getItem('evaluation-selected-tab')){
            return parseInt(localStorage.getItem('evaluation-selected-tab'));
        }else{
            return 0;
        }
    }
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [selectedTab, setSelectedTab] = useState( getSelectedTab())
    const [search, setSearch] = useState('');
    const [departments, setDepartments] = useState([])

 
   
    useEffect(() => {
        localStorage.setItem('evaluation-selected-tab', selectedTab)
    }, [selectedTab])

    useEffect(() => {
        if (context.organisation) {
            ServiceDepartments.getForOrganisation(context.organisation._id)
                .then(results => {

                    setDepartments(results);

                })
        }
    }, [context.organisation])

    useEffect(() => {
   //     contextUI.setShowHeader(true);
   //     contextUI.setSubTitle('Dashboard');
   //     contextUI.setShowBackButton(false);

        if (context.organisation) {
            getItems();


            let menuItems = [];
            let hasRights = false;
            //menu.push();
            menuItems.push(<MenuItem >Toevoegen evaluatie voor:</MenuItem>);
            if (ServiceUser.canWrite(context.user.rights, 'organisations-evaluation', context.organisation._id)) {
                menuItems.push(<MenuItem onClick={() => { addItem() }}>{context.organisation.name}</MenuItem>);
                hasRights = true;
                menuItems.push(<hr />)
            }

            for (let d = 0; d < departments.length; d++) {
                if (ServiceUser.canWrite(context.user.rights, 'departments-evaluation', departments[d]._id)) {
                    menuItems.push(<MenuItem onClick={() => { addItem(departments[d]._id) }}>{departments[d].name}</MenuItem>);
                    hasRights = true;
                }
            }
            let menu;
            if (hasRights) menu = <Menu control={<IconButton><Add color="white" /></IconButton>} >{menuItems}   </Menu>
            contextUI.setHeaderActions(<>{menu}<IconButton onClick={() => context.setShowFAQ(0)}><Help color="white" /></IconButton></>)


        }
    }, [context.organisation, departments])

    //GETTING ITEMS FROM API
    const getItems = () => { Service.getForOrganisation(context.organisation._id).then(items => setItems(GeneralService.sortJSON(items, 'title', '123'))).catch(err => { console.log(err) }) }

    const addItem = (department) => {
        Service.insert({ organisation: context.organisation._id, department: department, title: 'Nieuwe evaluatie' }).then(item => { viewItem(item._id); }).catch(ex => { console.log(ex) })
    }

    const viewItem = (id) => { props.history.push('/evaluatie/' + id) }

    useEffect(() => {
        setItemsFiltered(items.filter(i => { return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase()) >= 0 }));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])

    return (
        < >
            <MainMenu  history={props.history}/>
            <div style={{minHeight:'800px'}}> 
                <Monitor history={props.history} />
            </div>
            <Footer/>
        </>
    )
}
