import React, { useState } from 'react'
import {TextField,Paper,IconButton} from './core';
import {Search,Close} from './coreIcons';

const  SearchBar = function(props) {
    const [searchWord, setSearchWord] = useState('');

    const keyUpEvent = (ev) => {
        if (props.onKeyUp) {
            props.onKeyUp(searchWord);
        }
        if (ev.keyCode === 13 && props.onSubmit) {
            props.onSubmit(searchWord)
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Paper style={{ margin: '10px', padding: '10px', paddingLeft: '12px' }}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: '100%' }}>
                                <TextField
                                    autoFocus
                                    id="search"
                                    value={searchWord}
                                    onChange={(ev) => { setSearchWord(ev.target.value) }}
                                    onKeyUp={(ev) => { keyUpEvent(ev) }}
                                    label=""
                                    placeholder="Zoeken..."
                                    style={{ width: '100%' }}
                                />
                            </td>
                            <td>
                                <IconButton color="primary" onClick={() => { props.onSubmit && props.onSubmit(searchWord) }} disabled={searchWord.length < 3}>   <Search /></IconButton>
                            </td>
                            <td>
                                <IconButton style={{marginRight:'7px'}} onClick={() => { setSearchWord('');props.onReset && props.onReset() }} ><Close /></IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Paper>
        </div>
    )
}
export {SearchBar}