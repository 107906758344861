import React,{useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, Typography} from '../ui/core'
export default function DialogFAQ({FAQs,onClose}) {
    const [currentQuestion,setCurrentQuestion] = useState()

    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    return (
        <Dialog onClose={onClose} fullScreen>
            <DialogTitle>
                <Typography component="h1">Veelgestelde vragen</Typography>
            </DialogTitle>
            <DialogContent>
                {FAQs.map(faq=>{
                    return   <ExpansionPanel key={faq._id} primary={<Typography component="h3">{faq.question}</Typography>} expanded={faq._id===currentQuestion} onClick={()=>{faq._id!==currentQuestion?setCurrentQuestion(faq._id):setCurrentQuestion()}}>
                    <div style={{padding:'20px'}}  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }} ></div>
                                </ExpansionPanel>
                })}
          
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Venster sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
