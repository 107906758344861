import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../AppContext';
import ServiceUsers from '../../services/user';
import ServiceGeneral from '../../services/general';
import SelectOrganisation from './quicksearch';

export default function Selector({history}) {
    const context = useContext(Context);
    const iconOrg = <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path fill="gray" d="M80-120v-720h400v160h400v560H80Zm80-80h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm320 480h320v-400H480v400Zm80-240v-80h160v80H560Zm0 160v-80h160v80H560Z" /></svg>
    const iconLogout = <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path fill="gray" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" /></svg>;
    const [organisations, setOrganisations] = useState([])

    const styleWrapper = { display: 'flex', padding: '5px', float: 'right', cursor: 'pointer', clear: 'both' }
    const styleText = { padding: '5px' }
    const styleImage = {}

    useEffect(() => {
        ServiceUsers.currentuserOrganisations()
            .then(organisations => {
                setOrganisations(ServiceGeneral.sortJSON(organisations, 'name', '123'));
                if (!context.organisation && organisations.length === 1) {
                    context.setOrganisation(organisations[0]);
                
                }
            })
    }, [])

    const logout = () => {
        if (window.confirm('Weet u zeker dat u wilt uitloggen?')) {
            context.logout();
        }
    }
    return (
        < div className="padding">
            {context.user && <div className='linkbutton' style={styleWrapper} onClick={() => { logout() }}><div style={styleText}>{context.user.firstname} {context.user.lastname} </div><div style={styleImage}>{iconLogout}</div></div>}
            {!context.organisation && <SelectOrganisation title="Selecteer de organisatie waar u mee wilt werken" items={organisations} onClick={(org)=>{context.setOrganisation(org);    history.push('/evaluatie');}} />}
            {context.organisation && <div className='linkbutton' style={styleWrapper} onClick={() => { context.setOrganisation(); history.push('/evaluatie'); }}> <div style={styleText}>{context.organisation.name}</div><div style={styleImage}> {iconOrg}</div></div>}

        </div>
    )
}
