import React, { useState, useContext, useEffect } from 'react'
import Service from '../../services/department';
import Item from '../../components/departments/item';
import { Tabs, Fab, Paper, IconButton, Button } from '../../components/ui/core';
import { Add } from '../../components/ui/coreIcons';
import { ContextUI } from '../../uiContext';
import { Context } from '../../AppContext';
import MainMenu from '../../components/mainmenu';
import Footer from '../../components/mainfooter';

export default function DepartmentItem(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [selectedTab, setSelectedTab] = useState(0)


    useEffect(() => {
        getItems();
        //  contextUI.setSubTitle('Locatie');
        //  contextUI.setShowBackButton(true);
        //  contextUI.setHeaderActions();

        return () => { }
    }, [selectedTab])

    const getItems = () => {
        Service.get(props.match.params.id)
            .then(item => {
                setItem(item)
            })
            .catch(err => { console.log(err) })
    }

    const handleSave = (item) => { 
        Service.update(item).then(result => { getItems() }).catch(ex => { console.log(ex) }) 
    }

    // if(!context.hasRights(context.user, 'fullcontrol-departments', 'w')) return null;

    return (<>
        <MainMenu history={props.history} />
        <div className='contentspacing'>
            <Button onClick={() => { props.history.goBack() }} variant="text" fullWidth> &lt; TERUG NAAR OVERZICHT LOCATIES</Button>
            <Item item={item} onSave={handleSave} />
        </div>
        <Footer />
    </>)
}
