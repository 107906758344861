import React, { useEffect, useState, useContext } from 'react'
import ServiceRespondent from '../../services/respondents';
import ServiceEvaluation from '../../services/evaluations';
import ServiceSettings from '../../services/settings';
import ServiceSurveys from '../../services/survey';
import InputForm from '../../components/module-survey/inputform/inputform'
import Container from '../../components/sidemenu/container';
import Menu from '../../components/sidemenu/menu';
import Content from '../../components/sidemenu/content';
import { Button, IconButton, SearchBar, Select, Tabs, TextArea, TextField, Typography } from '../../components/ui/core';
import { ChevronRight, Close } from '../../components/ui/coreIcons';
import useWindowSize from '../../hooks/windowsize';
import { ContextUI } from '../../uiContext';
import Report from '../../components/module-survey/overview/processes';
import { nanoid } from 'nanoid';
export default function Survey(props) {
    const contextUI = useContext(ContextUI)
    const windowsize = useWindowSize();
    const [selectedView, setSelectedView] = useState(0);
    const [respondentInfo, setRespondentInfo] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [selectedSubProcess, setSelectedSubProcess] = useState('');
    const [hideMenu, setHideMenu] = useState(false);
    const [note, setNote] = useState(window.localStorage.getItem('surveynote'));

    const [evaluation, setEvaluation] = useState();

    const [surveyID, setSurveyID] = useState();
    const [respondentID, setRespondentID] = useState();
    const [respondentCode, setRespondentCode] = useState();

    //for showing evaluation, creating new respondent
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedSurvey, setSelectedSurvey] = useState();
    const [disableSelectSurvey, setDisableSelectSurvey] = useState(false)
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        if (props.match.params.id) {
            let paramSplit = props.match.params.id.split('-');
            if (paramSplit.length === 3) {
                //get survey
                setSurveyID(paramSplit[2]);
                setRespondentCode(paramSplit[1]);
                setRespondentID(paramSplit[0]);
            } else {
                //get evaluation
                if (paramSplit[0] == "S") {
                    ServiceEvaluation.getByCode(paramSplit[1], paramSplit[2])
                        .then(evaluation => {
                            setEvaluation(evaluation);
                            ServiceSurveys.getForEvaluation(evaluation._id)
                                .then(surveys => {
                                    setSurveys(surveys);
                                    if (paramSplit[3] && paramSplit[3] !== '0') {
                                        setSelectedSurvey(paramSplit[3]);
                                        setDisableSelectSurvey(true)
                                    }
                                })

                        })
                }

            }
        }
    }, [])

    useEffect(() => {
        if (note !== null && note !== undefined) {
            window.localStorage.setItem('surveynote', note)
        }

    }, [note])

    const getInfo = () => {
        ServiceRespondent.getAllForRespondent(surveyID, respondentID, respondentCode)
            .then(result => {
                setRespondentInfo(result);
            })
    }

    useEffect(() => {
        if (surveyID && respondentID && respondentCode) {
            getInfo();
        }
    }, [surveyID, respondentID, respondentCode])

    useEffect(() => {
        if (windowsize.innerWidth < 700) {
            setHideMenu(true);
        } else {
            setHideMenu(false);
        }
    }, [windowsize.innerWidth])

    const handleClose = () => {

    }

    const handleChange = () => {
        getInfo();
    }


    const createNewForm = () => {
        if (name && email && email.indexOf('@') > 0 && selectedSurvey) {
            setSelectedSurvey();
            ServiceSettings.get()
                .then(settings => {
                    ServiceRespondent.insert({ name: name, email: email, survey: selectedSurvey, code: nanoid(20).replace(/-/g, '4'), mailMessage: (settings.mailsurveyrespondent || '') })
                        .then(result => {
                            let url = 'https://evaluatie.scherpinveiligheid.nl/#/vragenlijst/' + result._id + '-' + result.code + '-' + result.survey;
                            ServiceRespondent.update({ _id: result._id, email: email, sendMail: true, mailMessage: (settings.mailsurveyrespondent || ''), url: url }).then(() => {
                                if (window.confirm(`De vragenlijst is aangemaakt. Een mail is verstuurd naar ${email} met daarin de rechtstreekse link naar de vragenlijst mocht u deze later willen bewerken.`)) {
                                    window.location.href = url;
                                    console.log('Redirecting to ', url);
                                    window.location.reload();
                                    return false;
                                } else {
                                    window.location.href = url;
                                    window.location.reload();
                                    return false;
                                }
                            }, (err) => { })
                        })
                        .catch(ex => { console.log(ex) })
                })
                .catch(ex => {
                    console.log(ex);
                })
        }
    }

    if (evaluation) return (
        <div className='padding'>
            <Typography component="h1">{evaluation.title}</Typography>
          
            <div style={{ whiteSpace: 'pre' }}>
                {evaluation.intro}
            </div>

            <TextField id="name" value={name} onChange={(ev) => { setName(ev.target.value) }} label="Uw naam" /><br />
            <TextField id="email" value={email} onChange={(ev) => { setEmail(ev.target.value) }} label="E-mail adres" /><br />
            {!disableSelectSurvey &&
                <Select id="selectedSurvey" label="Vragenlijst" value={selectedSurvey} onChange={(ev) => { setSelectedSurvey(ev.target.value) }} disabled={true} >
                    <option value={''}  >Selecteer een vragenlijst</option>
                    {surveys.filter(survey => { return survey.active === true }).map(survey => {
                        return <option value={survey._id} key={survey._id}>{survey.title}</option>
                    })}
                </Select>}
            <Button onClick={createNewForm} disabled={!((name && email && email.indexOf('@') > 0 && selectedSurvey))}>Aanmaken nieuw invulformulier</Button>
        </div>
    );

    if (!respondentInfo) return null;
    return (
        <Container offset={0}>
            <Menu width={hideMenu ? 40 : 350}>
                {!hideMenu && <>
                    <div style={{ backgroundColor: '#ff8036', color: 'white', height: '50px', paddingTop: '8px' }}>
                        <div style={{ float: 'right', paddingTop: '5px', paddingRight: '18px' }} onClick={() => { setHideMenu(true) }}><IconButton><Close color="white" /></IconButton></div>

                        <div className="padding">Voortgang {Math.round(100 / respondentInfo.respondent.countquestions * respondentInfo.respondent.countanswers)}% </div>
                    </div>

                    <SearchBar onKeyUp={setSearchValue} onReset={() => { setSearchValue('') }} />

                    <div className="padding">
                        {respondentInfo.survey.mainProcesses && respondentInfo.survey.mainProcesses.filter(pro => { return pro.active === true }).length > 0 && <>
                            {respondentInfo.survey.mainProcesses.filter(pro => { return pro.active === true }).map(main => {
                                let subProcesses = [];
                                subProcesses = main.subProcesses && main.subProcesses.filter(sub => { return sub.active === true }).map(sub => {
                                    return <div key={sub._id} style={{ cursor: 'pointer', padding: '8px' }} onClick={() => { setSelectedSubProcess(sub._id) }}>{sub.title}</div>
                                })
                                return <div key={main._id}>
                                    <b>{main.title}</b><br />
                                    {subProcesses}
                                </div>
                            })}
                        </>}
                    </div>
                    <div className="padding"> <TextArea id="note" label="Aantekeningen" style={{ width: '100%', height: '100%', backgroundColor: 'rgba(216,216,216,0.5)' }} value={note || ''} onChange={(ev) => { setNote(ev.target.value) }}></TextArea></div>

                </>}
                {hideMenu && <>
                    <IconButton onClick={() => { setHideMenu(false) }} style={{ marginTop: '10px' }}><ChevronRight /></IconButton>
                </>}

            </Menu>
            <Content >
                <div >
                    <div className="padding"> <Typography component="h1">{respondentInfo.survey.title}</Typography></div>

                    <Tabs value={selectedView} onChange={setSelectedView}>
                        <div className="padding">VRAGENLIJST</div>
                        <div className="padding">RESULTAAT</div>
                    </Tabs>
                    {selectedView === 0 && <div className="padding">
                        <InputForm filter={searchValue} respondent={respondentInfo.respondent} optionalSurvey={respondentInfo.survey} optionalQuestions={respondentInfo.questions} optionalParams={respondentInfo.params} onClose={handleClose} onChange={handleChange} selectedSubProcess={selectedSubProcess} setSelectedSubProcess={setSelectedSubProcess} showSaveButton={true} />

                    </div>}

                    {selectedView === 1 && <div className="padding">
                        <div className="label"><i>Onderstaande resultaten toont het gemiddelde van alle waarnemers.</i></div>
                        <Report survey={respondentInfo.survey} />
                    </div>}

                </div>
            </Content>
        </Container>

    )
}
