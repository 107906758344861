import React, { useState, useEffect, useContext } from 'react'
import './styles.css';
import { Context } from '../../AppContext';

export default function Footer() {
    const context = useContext(Context);
    const iconDownload = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill='white' d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
    const styleWrapper = { display: 'flex', cursor: 'pointer !important' }
    const styleText = { padding: '5px' }
    const styleImage = {}
    const [backgroundColor, setBackgroundColor] = useState('#006a8e');

    useEffect(() => {
        if (context.organisation && context.organisation.color) { setBackgroundColor(context.organisation.color) } else { setBackgroundColor('#006a8e') }

    }, [context.organisation]);

    return (
        <div className='footer' style={{ backgroundColor: backgroundColor }}>
            <div className='footercontent'>
                <div className='footercontenttext'>
                    Dit is een product van<br /><br />
                    <img src='/logo-wit.svg' width="150px" /><br /><br />
                    Kroonbeursweg 67 | 8442 DJ | Heerenveen<br />
                    +31 85 0161 357<br />
                    info@scherpinveiligheid.nl
                </div>
                <div className='footercontenttext '>
                    <a href="/Klachtenregeling.pdf"><div style={styleWrapper}><div style={styleImage}>{iconDownload}</div><div style={styleText}> Klachtenregeling</div> </div></a><br />
                    <a href="/Privacy_statement.pdf"><div style={styleWrapper}><div style={styleImage}>{iconDownload}</div><div style={styleText}> Privacy statement</div> </div></a><br />
                </div>
            </div>
            <div className='copyright'>© 2024 Scherp in Veiligheid </div>
        </div>
    )
}
