import React, { useState } from 'react'
import { Typography } from '../ui/typography';

export default function List({ items, onClick }) {
    const [hover, setHover] = useState([]);

    const toggle = (id) => {
        let tmpItem = Object.assign([], hover);
        if (tmpItem.indexOf(id) >= 0) {
            tmpItem = tmpItem.filter(item => item !== id)
            setHover([])
        } else {
            tmpItem.push(id)
            setHover(tmpItem)
        }
    }

    return (
        < >
      
            {items
                .map(item => {
                    return <div key={item._id} onClick={() => { onClick(item) }} className="card" onMouseOver={() => { toggle(item._id) }} onMouseOut={() => { toggle(item._id) }}>
                        <div className="card-image" style={{ backgroundImage: 'url(' + (item.image || 'http://localhost:3001/chart.png') + ')' }}></div>
                        <div className="card-line">&nbsp;</div>
                        <div className="card-content">
                            <Typography component="h3">{item.title}</Typography>

                            <i>{item.description}</i>
                        </div>
                        <div className='card-footer'>
                            {hover.indexOf(item._id) >= 0 ?
                                <div className='card-footer-link card-footer-link-active'> <div style={{ postition: 'relative', float: 'right', paddingTop: '5px' }}> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="white" d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg></div><div style={{ postition: 'relative', float: 'right', marginTop: '8px' }}> BEKIJK</div></div>
                                :
                                <div className='card-footer-link'><div style={{ postition: 'relative', float: 'right' }}> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="gray" d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg></div></div>

                            }
                        </div>
                    </div>
                })}
        </>
    )
}
