import React, { useEffect, useState } from 'react'
import Service from '../../../services/question';
import GeneralService from '../../../services/general'
import { Button, Menu, MenuItem, ExpansionPanel, Paper, CheckBox, Tooltip } from '../../ui/core';
import Question from './question';
import Import from './import';
import { v4 as uuidv4 } from 'uuid';
export default function Index({ survey, onSave }) {
    const [questions, setQuestions] = useState([]);
    const [showImport, setShowImport] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [focusItem, setFocusItem] = useState();

    useEffect(() => {
        getQuestions();
    }, [])

 
    const getQuestionsResort = () => {
        return new Promise(async (resolve, reject) => {
            Service.getForSurvey(survey._id)
                .then(async(result) => {
                    let unResortedQuestions = GeneralService.sortJSON(result, 'sort', '123');
                    let updates = [];
                    let counter = 1;

                    for (let q = 0; q < unResortedQuestions.length; q++) {
                        updates.push({ _id: unResortedQuestions[q]._id, sort: counter })
                        counter++;
                    }
                    for (let u = 0; u < updates.length; u++) {
                        await Service.update(updates[u]);
                    }
                    Service.getForSurvey(survey._id)
                    .then(result => { setQuestions(GeneralService.sortJSON(result, 'sort', '123')); resolve(); })
                    .catch(err => { console.log(err);reject(err)  })
                   
                })
                .catch(err => { console.log(err); reject(err) })
        })
    }


    const getQuestions = () => {
        Service.getForSurvey(survey._id)
            .then(result => { setQuestions(GeneralService.sortJSON(result, 'sort', '123')) })
            .catch(err => { console.log(err) })
    }

    const addQuestion = (type) => {
        let maxNumber = 1;
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].sort && questions[i].sort >= maxNumber) {
                maxNumber = parseInt(questions[i].sort) + 1
            }
        }
        Service.insert({ survey: survey._id, question: '', type: type, sort: parseInt(maxNumber) })
            .then(result => {
                setFocusItem(result._id);
                getQuestions();
            })
            .catch(ex => { console.log(ex) })
    }

    const handleSave = (item) => { Service.update(item).then(result => { }).catch(ex => { console.log(ex) }) }
    const handleDelete = (item) => { Service.remove(item).then(result => { getQuestionsResort(); }).catch(ex => { console.log(ex) }) }
    const handleCopy = (item) => {
        let newItem = Object.assign({}, item);
        newItem._id = undefined;
        newItem.sort = item.sort;
        newItem.options = [];
        item.options.forEach(option => {
            let newOption = Object.assign({}, option);
            newOption._id = uuidv4();
            newItem.options.push(newOption);
        });
        newItem.created = new Date();
        Service.insert(newItem)
            .then(result => {
                setFocusItem(result._id);
               // getQuestions();
               getQuestionsResort();
            })
            .catch(ex => { console.log(ex) })

    }

    const handleCloseImport = () => {
        getQuestions();
        setShowImport(false);
    }

    const handleChangeSort = (question,direction) => {
        let update = {};
        update._id = question._id;
        update.sort = direction === 'up'? question.sort -2 : question.sort +2;
        Service.update(update).then(result => { getQuestionsResort(); }).catch(ex => { console.log(ex) })
    }

    return (
        <div >
            {showImport && <Import survey={survey} onClose={handleCloseImport} onSaveSurvey={onSave} />}
            <div style={{ float: 'right' }} className='padding' > <Tooltip label="Toon uitgebreide opties" >   <CheckBox checked={advanced} onClick={() => { setAdvanced(!advanced) }} label="Geavanceerd" /></Tooltip></div>
            <br style={{ clear: 'both' }} />
            {questions.map(question => {
                return <Paper onClick={() => { setFocusItem(question._id) }} key={'panel' + question._id} className="padding" style={{ marginBottom: '8px', border: focusItem === question._id ? '1px solid black' : '1px solid transparent' }}><Question key={question._id} focus={question._id === focusItem} mainProcesses={survey.mainProcesses} question={question} onSave={handleSave} onCopy={handleCopy} onDelete={handleDelete} advanced={advanced} onChangeSort={handleChangeSort}></Question></Paper>
            })}
            <br />
            <Menu id="questionselectmenu" control={<Button fullWidth variant="outlined" >Vraag toevoegen</Button>}>
                <MenuItem onClick={() => addQuestion(0)}>Tekstblok</MenuItem>
                <MenuItem onClick={() => addQuestion(1)}>Open vraag, enkele regel</MenuItem>
                <MenuItem onClick={() => addQuestion(2)}>Open vraag, meerdere regels</MenuItem>
                <MenuItem onClick={() => addQuestion(3)}>Gesloten vraag,<br /> 1 antwoord mogelijkheid</MenuItem>
                <MenuItem onClick={() => addQuestion(4)}>Gesloten vraag,<br /> meerdere antwoord mogelijkheden</MenuItem>
            </Menu>
            <Button fullWidth variant="outlined" onClick={() => { setShowImport(true) }}>Vragenlijst importeren</Button>
        </div>
    )
}
