import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/survey';
import ServiceDocument from '../../../services/document';
import Item from '../../../components/module-survey/item';
import Builder from '../../../components/module-survey/builder/index';
import Settings from '../../../components/module-survey/settings/index';

import { Tabs, Paper, Button, IconButton, Menu, MenuItem } from '../../../components/ui/core';
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';

import Uploaddocument from '../../../components/general/uploaddocument';
import Documents from '../../../components/general/documents';
import { MoreVert } from '../../../components/ui/coreIcons';

import MainMenu from '../../../components/mainmenu';
import Footer from '../../../components/mainfooter';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [selected, setSelected] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [uploadNewDocument, setUploadNewDocument] = useState('');

    const handleDelete = () => {
        var check = window.confirm("Weet u zeker dat u deze vragenlijst wilt verwijderen?");
        if (check) {
            Service.remove({ _id: props.match.params.id })
                .then(result => {
                    props.history.push('/beheer/vragenlijsten');
                })
                .catch(ex => { console.log(ex) })
        }
    }

    const handleCopyTemplate = () => {
        Service.copyTemplate(props.match.params.id, '0')
            .then(result => {
                props.history.push('/beheer/vragenlijsten');
            })
            .catch(ex => { })
    }

    useEffect(() => {
        getItem();
        getDocuments();
        contextUI.setSubTitle('Vragenlijst');
        contextUI.setShowBackButton(true);

        let menu = <Menu id="additemmenu" noscroll={true} control={<IconButton><MoreVert color="white" /></IconButton>}>
            <MenuItem onClick={() => { handleCopyTemplate() }}>Kopieer vragenlijst</MenuItem>
            <MenuItem onClick={() => { handleDelete() }}>Verwijder vragenlijst</MenuItem>
        </Menu>
        contextUI.setHeaderActions(menu);
        return () => { }
    }, [])

    const getItem = () => { Service.get(props.match.params.id).then(item => setItem(item)).catch(err => { console.log(err) }) }

    const handleSave = (item) => { Service.update(item).then(result => { getItem() }).catch(ex => { console.log(ex) }) }

    const getDocuments = () => {
        ServiceDocument.getForModule('surveys_report', props.match.params.id)
            .then(result => {
                setDocuments(result);
            })
    }

    const handleNewDocument = (document) => {
        document.module = uploadNewDocument;
        document.moduleid = item._id;
        ServiceDocument.insert(document)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { })
        setUploadNewDocument('');
    }

    const handleDeleteDocument = (doc) => {
        ServiceDocument.remove(doc)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { console.log(ex) })
    }

    if (!context.hasRights(context.user, 'fullcontrol-surveys', 'w')) return null;
    /* <div className="padding">RAPPORTAGES</div> */

    return (<>
        <MainMenu history={props.history} />
        <Button onClick={() => { props.history.goBack() }} variant="text" fullWidth> &lt; TERUG NAAR OVERZICHT VRAGENLIJSTEN</Button>
        <div className='contentspacing padding'>
         
            {uploadNewDocument.length > 0 && <Uploaddocument module={uploadNewDocument} onSave={handleNewDocument} onClose={() => { setUploadNewDocument('') }} />}
            {item && <>
                <Paper><Item item={item} onSave={handleSave} /></Paper>
                <br />
                <Paper>
                    <Tabs value={selected} onChange={(val) => { setSelected(val) }}>
                        <div className="padding">VRAGEN</div>
                        <div className="padding">INSTELINGEN</div>

                    </Tabs>
                    <br />
                    {selected === 0 && <div className="padding"><Builder survey={item} onSave={handleSave} /></div>}
                    {selected === 1 && <div className="padding"><Settings survey={item} onSave={handleSave} /></div>}
                    {selected === 2 && <>
                        {<Documents documents={documents} onDelete={handleDeleteDocument} />}
                        <Button fullWidth variant={'outlined'} onClick={() => { setUploadNewDocument('surveys_report') }}>Document toevoegen</Button>
                    </>}
                </Paper>
            </>}

        </div>
        <Footer />
    </>
    )
}
