import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../AppContext';
import ServiceUsers from '../../services/user';
import "./styles.css"
import ScherpLogo from '../../graphics/scherp.svg'
import SelectOrganisation from '../../components/organisations/selector';
export default function Index({ history }) {
    const context = useContext(Context);
    const [showAdmin, setShowAdmin] = useState(false);
    const [hasAdmin, setHasAdmin] = useState(false);
    const [image,setImage] = useState(ScherpLogo);
    const [backgroundColor,setBackgroundColor] = useState('#006a8e');

    useEffect(() => {
        ServiceUsers.currentuser()
        .then(user => { context.setUser(user) })
        .catch(err => { context.setUser(null) ;  history.push('/login');});
    }, [] );

    useEffect(() => {
        if(context.organisation && context.organisation.image){setImage(context.organisation.image)}else{setImage(ScherpLogo)}
        if(context.organisation && context.organisation.color){setBackgroundColor(context.organisation.color)}else{setBackgroundColor('#006a8e')}
        if (context.organisation && ServiceUsers.canWrite(context.user.rights, 'organisations', context.organisation._id)) setHasAdmin(true);
        if (context.hasRights(context.user, 'fullcontrol-organisations', 'w') || context.hasRights(context.user, 'fullcontrol-users', 'w') || context.hasRights(context.user, 'fullcontrol-surveys', 'w') || context.hasRights(context.user, 'fullcontrol-elearnings', 'w') || context.hasRights(context.user, 'fullcontrol-checklists', 'w')) { setHasAdmin(true) };
     
    }, [context.user, context.organisation]);

    return (
        <>
            <div className="logobar"  >
                <div className="logobarlogo">   <img src={image} alt="Scherp" style={{ height: '60px', width: 'auto', padding: '23px' }} /></div>
                <div className="logobarinfo">  <SelectOrganisation history={history} /></div>
            </div>
            <div className='menubar' style={{backgroundColor:backgroundColor}}>
                <div>
                    <div className='menubutton' onClick={() => { setShowAdmin(false); history.push('/monitor') }}>  Monitor</div>
                    <div className='menubutton' onClick={() => { setShowAdmin(false); history.push('/evaluatie') }}>   Evaluatie</div>
                    {<div className='menubutton' onClick={() => { setShowAdmin(!showAdmin) }}>Beheer</div>}
                </div>
                <div style={{ textAlign: 'right', }}>
                    <div className='menuiconbutton' onClick={() => context.setShowFAQ(0)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path fill="white" d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg></div>
                </div>
            </div>
            {showAdmin && <div className='menuadmin' style={{backgroundColor:backgroundColor}}>
                {context.organisation && ServiceUsers.canWrite(context.user.rights, 'organisations', context.organisation._id) &&
                <>
                   <h2 style={{color:'white'}}>Beheer organisatie</h2>        
                    <div style={{ padding: '10px', color: 'white' }} className="linkbutton" onClick={() => { context.setModule('organisation'); window.location.href = '/#/organisatie' }}>
                             
                            Locatie en gebruikersbeheer {context.organisation.name}
                   
                    </div>
                    </>
                }
                  {(context.hasRights(context.user, 'fullcontrol-organisations', 'w') || context.hasRights(context.user, 'fullcontrol-users', 'w') || context.hasRights(context.user, 'fullcontrol-surveys', 'w') || context.hasRights(context.user, 'fullcontrol-elearnings', 'w') || context.hasRights(context.user, 'fullcontrol-checklists', 'w')) &&
                    <>
                    <h2 style={{color:'white'}}>Beheer tools voor de Scherp medewerker</h2>        

                    {context.hasRights(context.user, 'fullcontrol-organisations', 'w') &&<div style={{ padding: '10px', color: 'white' }} className="linkbutton" onClick={() => { context.setModule('beheer'); window.location.href = '/#/beheer/organisaties' }}>
                       Organisaties
                    </div>}
                    {context.hasRights(context.user, 'fullcontrol-users', 'w') && <div style={{ padding: '10px', color: 'white' }} className="linkbutton" onClick={() => { context.setModule('beheer'); window.location.href = '/#/beheer/gebruikers' }}>
                       Gebruikers
                    </div>}
                    {context.hasRights(context.user, 'fullcontrol-settings', 'w') &&   <div style={{ padding: '10px', color: 'white' }} className="linkbutton" onClick={() => { context.setModule('beheer'); window.location.href = '/#/beheer/instellingen' }}>
                       Instellingen app
                    </div>}
                    {context.hasRights(context.user, 'fullcontrol-surveys', 'w') &&  <div style={{ padding: '10px', color: 'white' }} className="linkbutton" onClick={() => { context.setModule('beheer'); window.location.href = '/#/beheer/vragenlijsten' }}>
                       Vragenlijsten
                    </div>}
                    </>}

            </div>}
        </>

    )
}
