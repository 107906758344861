import API from "./api";
const Service = {
    fileToBase64: (file, width) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                if (!width) {
                    resolve(reader.result);
                } else {
                    try{
                        console.log('creating canvas')
                        var canvas = document.createElement('canvas');
                        var img = new Image();
                        img.src = reader.result;
                        img.onload = () => {
                            try{
                                canvas.width = width;
                                canvas.height = canvas.width * img.height / img.width;
                                var context = canvas.getContext('2d');
                                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                                resolve(canvas.toDataURL('image/jpeg', 0.5));
                            }catch(ex){
                                console.log('Error: ', ex);
                                reject(ex);
                            }
                           
                        }
                    }catch(ex){
                        console.log('Error: ', ex);
                        reject(ex);
                    }
                   
                };
                reader.onerror = function (error) {
                    reject(error);
                    console.log('Error: ', error);
                };
            }
        })
    },
}
export default Service;