
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '../plugins/capacitor';
import FileService from '../services/file';
import Settings from '../AppSettings';

const locationAPI = Settings.getLocationAPI();

const Service = {
   
}
export default Service;