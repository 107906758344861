import React from 'react'

import useWindowSize from '../../hooks/windowsize';
export default function Container({children,offset=64}) {
    const windowsize = useWindowSize();
    const container = { display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width:'100%', }
  
    return (
        <div  style={container}>
             {children}
        </div>
    )
}
