import React, { useEffect, useState } from 'react'
import { Typography, Button, TextArea, TextField, CheckBox, IconButton, Fab, DatePicker } from '../../ui/core'
import { Add, Delete } from '../../ui/coreIcons'
import { v4 as uuidv4 } from 'uuid';
import ServiceOrganisations from '../../../services/organisation';
export default function Index({ survey, onSave ,onCopy}) {
    const [item, setItem] = useState()
    const [params, setParams] = useState([])

    useEffect(() => {
        let tmpItem = Object.assign({}, survey);
        if (!tmpItem.mainProcesses) { tmpItem.mainProcesses = []; }
        if (!tmpItem.subProcesses) { tmpItem.subProcesses = []; }
        if (!tmpItem.colorRange) { tmpItem.colorRange = [] }
        setItem(tmpItem)

        ServiceOrganisations.get(survey.organisation)
            .then(organisation => { setParams(organisation.params || []) })
            .catch(ex => { setParams([]) })

    }, [survey])

    const handleChange = (attribute, value) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[attribute] = value;
        setItem(tmpItem);
    }

    const handleSave = () => {
        let tmpItem = Object.assign({}, item);
        onSave(tmpItem);
    }


    const handleChangeAndSave= (attribute,value)=>{
        let tmpItem = Object.assign({}, item);
        tmpItem[attribute] = value;
        setItem(tmpItem);
        onSave(tmpItem);
      
    }

    const handleChangeDate = (date) => {
        let tmpItem = Object.assign({}, item);
        tmpItem.date = date;
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const addMainProcess = () => {
        let tmpItem = Object.assign({}, item);
        tmpItem.mainProcesses.push({ _id: uuidv4(), title: 'Hoofd proces ..', code: '', active: true });
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const changeMainProcess = (process, attribute, value) => {
        let tmpItem = Object.assign({}, item);
        let found = tmpItem.mainProcesses.find(t => { return t._id === process._id })
        if (found) {
            found[attribute] = value;
        }
        setItem(tmpItem);
    }

    const deleteMainProcess = (process) => {
        if (window.confirm('Weet u zeker dat u dit process wilt verwijderen?')) {
            let tmpItem = Object.assign({}, item);
            let found = tmpItem.mainProcesses.find(t => { return t._id === process._id })
            if (found) {
                found.active = false;
            }
            setItem(tmpItem);
            onSave(tmpItem);
        }
    }

    const addSubProcess = (process) => {
        let tmpItem = Object.assign({}, item);
        let found = tmpItem.mainProcesses.find(t => { return t._id === process._id })
        if (found) {
            if (!found.subProcesses) found.subProcesses = [];
            found.subProcesses.push({ _id: uuidv4(), title: 'Sub proces ..', code: '', active: true });
        }

        setItem(tmpItem);
    }



    const changeSubProcess = (process, attribute, value) => {
        let tmpItem = Object.assign({}, item);
        for (let i = 0; i < tmpItem.mainProcesses.length; i++) {
            if (tmpItem.mainProcesses[i].subProcesses) {
                let found = tmpItem.mainProcesses[i].subProcesses.find(t => { return t._id === process._id })
                if (found) {
                    found[attribute] = value;
                }
            }
        }

        setItem(tmpItem);
    }

    const deleteSubProcess = (process) => {
        let result = window.confirm('Weet u zeker dat u dit process wilt verwijderen?');
        if (result) {
            let tmpItem = Object.assign({}, item);
            for (let i = 0; i < tmpItem.mainProcesses.length; i++) {
                if (tmpItem.mainProcesses[i].subProcesses) {
                    let found = tmpItem.mainProcesses[i].subProcesses.find(t => { return t._id === process._id })
                   
                    if (found) {
                        found.active = false;
                    }
                }
            }
            setItem(tmpItem);
            onSave(tmpItem);
        }

    }

    const handleChangeCheckParam = (param, checked) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.params) tmpItem.params = [];
        if (tmpItem.params.indexOf(param._id) >= 0) {
            tmpItem.params.splice(tmpItem.params.indexOf(param._id), 1)
        }
        if (checked) {
            tmpItem.params.push(param._id)
        }

        setItem(tmpItem);
        onSave(tmpItem);
    }

    const handleDeleteSurvey = () => {
        if (window.confirm('Weet u zeker dat u dit wilt verwijderen?')) {
            let tmpItem = Object.assign({}, item);
            tmpItem.active = false;
            setItem(tmpItem);
            onSave(tmpItem);
        }

    }

    const addColorRange = (process) => {
        let tmpItem = Object.assign({}, item);

        tmpItem.colorRange.push({ _id: uuidv4(), from: 0, to: 100, color: '#00698f', active: true });
        setItem(tmpItem);
    }

    const handleChangeColorRange = (range, attribute, value) => {
        let tmpItem = Object.assign({}, item);
        let found = tmpItem.colorRange.find(r => { return r._id === range._id });
        if (found) {
            found[attribute] = value;
            setItem(tmpItem);
            onSave(tmpItem);
        }
    }

    const handleDeleteColorRange = (range) => {
        if (window.confirm('Weet u zeker dat u dit wilt verwijderen?')) {
            let tmpItem = Object.assign({}, item);
            tmpItem.colorRange = tmpItem.colorRange.filter(r => { return r._id !== range._id })
            setItem(tmpItem);
            onSave(tmpItem);
        }
    }


    if (!item) return null;
    return (
        <>
            <Typography component="h3">Titel</Typography>
            <TextField id="title" value={item.title} onChange={(ev) => { handleChange('title', ev.target.value); }} onBlur={handleSave}></TextField>
            {survey.template === true && <>
                <br/>   <br/>
                <div className="label">Publiceren</div>
                    <CheckBox label="Vragenlijst is beschikbaar" checked={item.published || false} onChange={(ev)=>{handleChangeAndSave('published',ev.target.checked)}}/>
                  
                <div className="label">Archiveren</div>
                    <CheckBox label="In archief plaatsen" checked={item.archived || false} onChange={(ev)=>{handleChangeAndSave('archived',ev.target.checked)}}/>
                    <div className="label">Wanneer dit item is gearchiveerd kan deze vragenlijst niet meer worden geselecteerd voor een evaluatie</div>
            </>}
            {survey.template !== true && <>
                <Typography component="h3">Uitvoerdatum/tijd evaluatie</Typography>
                <DatePicker value={survey.date} showTimeSelect={true} onChange={handleChangeDate} />
                <br />
            </>}
            {survey.forevaluation === true && <>
                {params.length > 0 && <>
                    <Typography component="h3">Parameters</Typography>
                    Selecteer de parameters die van toepassing zijn voor deze evaluatie<br /><br />
                    {params.filter(p => { return p.active === true }).map(param => {
                        return <CheckBox key={'cb_par_' + param._id} id={'par_' + param._id} label={param.name || ''} checked={item.params && item.params.indexOf(param._id) >= 0} onChange={(ev) => handleChangeCheckParam(param, ev.target.checked)} />

                    })}
                </>}

                <br />

                <div >
                    <Typography component="h3">Hoofdprocessen</Typography>
                    <table style={{ width: '100%' }}><tbody>
                        {item.mainProcesses.filter(p => { return p.active === true }).length > 0 && <tr><td className="label">Titel</td><td className="label">Code</td><td></td></tr>}
                        {item.mainProcesses.filter(p => { return p.active === true }).map((process) => {
                            let tmpRow =
                                <tr key={process._id}>
                                    <td valign="top" ><TextField id={process._id + 'title'} value={process.title} onChange={(ev) => { changeMainProcess(process, 'title', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                                    <td valign="top" ><TextField id={process._id + 'code'} value={process.code} onChange={(ev) => { changeMainProcess(process, 'code', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                                    <td valign="top"  ><IconButton onClick={() => { deleteMainProcess(process) }}><Delete /></IconButton></td>
                                    <td valign="top">
                                        <table><tbody>
                                            {process.subProcesses && process.subProcesses.filter(p => { return p.active === true }).length > 0 && <tr><td className="label">Titel subproces</td><td className="label">Code subproces</td><td></td></tr>}
                                            {process.subProcesses && process.subProcesses.filter(p => { return p.active === true }).map(subProcess => {
                                                return <tr key={subProcess._id}>
                                                    <td valign="top" ><TextField id={subProcess._id + 'title'} value={subProcess.title} onChange={(ev) => { changeSubProcess(subProcess, 'title', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                                                    <td valign="top" ><TextField id={subProcess._id + 'code'} value={subProcess.code} onChange={(ev) => { changeSubProcess(subProcess, 'code', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                                                    <td valign="top"  ><IconButton onClick={() => { deleteSubProcess(subProcess) }}><Delete /></IconButton></td>
                                                </tr>
                                            })}
                                        </tbody></table>
                                        <Button fullWidth style={{ float: 'right' }} onClick={() => addSubProcess(process)} variant="outlined">Toevoegen subproces</Button>
                                    </td>
                                </tr>
                            return tmpRow
                        })}
                    </tbody></table>
                    <Button fullWidth onClick={addMainProcess}>Toevoegen hoofdproces</Button>
                    <br /><br />

                </div>

                <Typography component="h3">Kleurweergave scores</Typography>
                <table style={{ width: '100%' }}><tbody>
                    <tr><td className="label">van (%)</td><td className="label">tot (%)</td><td className="label">verwoording</td><td className="label">kleurcode</td><td></td></tr>
                    {item.colorRange.map(range => {
                        return <tr key={range._id}>
                            <td><TextField type="number" id={range._id + 'from'} value={range.from || 0} onChange={(ev) => { handleChangeColorRange(range, 'from', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                            <td><TextField type="number" id={range._id + 'to'} value={range.to} onChange={(ev) => { handleChangeColorRange(range, 'to', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                            <td><TextField id={range._id + 'text'} value={range.text} onChange={(ev) => { handleChangeColorRange(range, 'text', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                            <td style={{ backgroundColor: range.color }}><TextField id={range._id + 'color'} value={range.color} onChange={(ev) => { handleChangeColorRange(range, 'color', ev.target.value) }} onBlur={() => { onSave(item) }} /></td>
                            <td valign="top"  ><IconButton onClick={() => { handleDeleteColorRange(range) }}><Delete /></IconButton></td>
                        </tr>
                    })}
                </tbody></table>
                <Button fullWidth onClick={addColorRange}>Toevoegen kleur</Button>


            </>}

            <br /><br />
            <Button onClick={onCopy} variant="outlined" fullWidth>Kopieer {survey.title} naar een nieuwe vragenlijst</Button>   <br /><br />
            <Button onClick={handleDeleteSurvey} style={{ color: 'red' }} variant="outlined" fullWidth>Verwijder {survey.title}</Button>
        

            <br />


        </>
    )
}
